import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';
import user from './UserReducer';
import pieces from './PiecesReducer';
import flows from './FlowReducer';

const mainReducer = combineReducers({
    user,
    pieces,
    flows
})

export default reduceReducers(mainReducer)