import React, {Component} from "react";
import {Card} from "primereact/card";
import {Button} from "primereact/button";
import {connect} from "react-redux";



class ErrorFallback extends Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}
	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		// You can also log the error to an error reporting service
		//logErrorToMyService(error, errorInfo);
		console.log(error, errorInfo);
	}

	reloadApp() {
		window.location.reload()
	};

	/*reportCrash() {

	}*/

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<Card>
					<div className="grid">
						<div className="col-12" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
							{/*<img src={this.props.errorImageUrl} alt="Something went wrong." width="600px"/>*/}
						</div>
					</div>
					<div className="grid">
						<div className="col-12" style={{marginTop: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
							<Button type="button" label="Reload App" size="large"
									onClick={() => { this.reloadApp() }}
									style={{marginRight: '20px'}}
							/>
							{/*<Button type="button" label="Report To Cloudmaven" size="large"
									onClick={() => { this.reportCrash() }}
							/>*/}
						</div>
					</div>
				</Card>
			);
		}

		return this.props.children;
	}
}

const mapStateToProps = state => ({
	// errorImageUrl: state.config.errorImageUrl
})

ErrorFallback = connect(
	mapStateToProps,
	null
)(ErrorFallback)

export default ErrorFallback