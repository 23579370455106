import React, {useEffect, useState} from 'react';
import {Card} from "primereact/card";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import {FlowStepSelector} from "../FlowNew/SideMenus/FlowStepSelector";
import {generateUniqueId, removeObjectFromArrayByKey} from "../../Utils/GeneralUtils";
import {faDownLong} from "@fortawesome/free-solid-svg-icons/faDownLong";
import {Chip} from "primereact/chip";
import {InputTextarea} from "primereact/inputtextarea";
import {Button} from "primereact/button";
import {faSave} from "@fortawesome/free-solid-svg-icons/faSave";
import {InputText} from "primereact/inputtext";
import {useParams} from "react-router";
import localbase from "localbase";

export const FlowEdit = (props) => {
    const [descriptionValue, setDescriptionValue] = useState('');
    const [flowNameValue, setFlowNameValue] = useState('');
    const [editFlow, setEditFlow] = useState([]);
    const params = useParams();
    let db = new localbase('ipaas')

    useEffect(() => {
        db.collection('Flows').doc(params.id).get().then(flow => {
            setEditFlow(flow.data.flowSteps)
            setDescriptionValue(flow.data.description)
            setFlowNameValue(flow.data.flowName)
        })
    }, []);

    const addSelectedFlow = (parent, child) => {

        let flowStep = {
            id: generateUniqueId(),
            parentName: parent.name,
            childName: child.name
        }
        const newArr = [...editFlow]
        newArr.push(flowStep)
        setEditFlow(newArr)
    }

    const deleteFlowStep = (id) => {
        let updatedFlow = removeObjectFromArrayByKey(editFlow, 'id', id)

        setEditFlow(updatedFlow)
    }

    const updateFlow = () => {
        console.log(editFlow)
        let flowDetails = {
            id: params.id,
            flowName: flowNameValue,
            description: descriptionValue,
            flowSteps: [
                ...editFlow
            ]
        }

        props.saveEditFlow(flowDetails)
    }

    const updateFlowName = (value) => {
        setFlowNameValue(value)
    }

    const updateFlowDescription = (value) => {
        setDescriptionValue(value)
    }

    return (
        <>
            <div style={{width: '100%'}}>
                <div className="flow-builder" style={{
                    overflow: 'scroll', display: 'inline-block', padding: '10px',
                    overflowY: 'scroll', height: '90vh'
                }}>
                    <h3>Edit Flow</h3>
                    <div style={{width: '250px', paddingLeft: '1rem', textAlign: 'center'}}>
                        <Chip label="Start"/>
                    </div>
                    {editFlow.map((each) => {
                        return (
                            <div key={each.id}>
                                <Card style={{width: '250px', height: '75px', margin: '10px'}} onClick={() => {
                                }}>
                                    <div style={{
                                        fontSize: '14px',
                                        whiteSpace: 'nowrap'
                                    }}>{each.parentName} - {each.childName}</div>
                                    <div style={{float: 'right', paddingTop: '7px'}}>
                                        {/*<FontAwesomeIcon icon={faPlusSquare} style={{
                                            cursor: 'pointer',
                                            color: "blue",
                                            marginRight: '10px'
                                        }}/>*/}
                                        <FontAwesomeIcon icon={faTrash} style={{cursor: 'pointer', color: "red"}}
                                                         onClick={() => deleteFlowStep(each.id)}
                                        />
                                    </div>
                                </Card>

                                <div style={{width: '250px', paddingLeft: '1rem', textAlign: 'center'}}>
                                    <FontAwesomeIcon icon={faDownLong}/>
                                </div>
                            </div>
                        )
                    })}
                    <div style={{width: '250px', paddingLeft: '1rem', textAlign: 'center'}}>
                        <Chip label="End"/>
                    </div>
                </div>

                <div style={{width: '300px', float: 'right'}}>
                    <h3>Flow Settings</h3>

                    <h5>Flow Details</h5>
                    <InputText value={flowNameValue}
                               onChange={(e) => {
                                   updateFlowName(e.target.value)
                               }}
                    />
                    <InputTextarea value={descriptionValue}
                                   onChange={(e) => {
                                       updateFlowDescription(e.target.value)
                                       //setValue(e.target.value)
                                   }}
                                   rows={5} cols={30}
                    />

                    <Button label="Update" type="button" icon={<FontAwesomeIcon icon={faSave} style={{marginRight: '15px'}} />} onClick={() => updateFlow()} />

                    <FlowStepSelector
                        addSelectedFlow={addSelectedFlow}
                    />
                </div>
            </div>
        </>
    )
}