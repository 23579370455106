import {HTTPService} from "./HTTPService";
import userEvent from "@testing-library/user-event";
import {cleanPersonioPayload, cleanYokoyPayload} from "../Utils/FlowUtils";


export class FlowExecutionService {

    async executeFlow(flow) {
        const flowService = new HTTPService()

        let responseFlowData = []
        for (const each of flow) {
            if (each.flowURL) {
                //let values = await flowService.executeFlowAPICall(each)
                responseFlowData[each.httpName] = await flowService.executeFlowAPICall(each)
            } else if (userEvent) {
                //console.log(JSON.stringify(responseFlowData))
                return responseFlowData;
            }
        }
    }

    async createYokoyUser(users) {
        const flowService = new HTTPService()

        // Reformat the values to mach the Yokoy Schema and POST to Yokoy
        let response = []
        for (const each of users) {
            let yokoyPayload = cleanYokoyPayload(each)
            let userCreateResponse = await flowService.executePostRequest(yokoyPayload, `https://cloud.activepieces.com/api/v1/webhooks/x9PhLAiGroIApGHkLqAV2/sync`)
            response.push(userCreateResponse)
            // Update Personio
            let personioPayload = cleanPersonioPayload(userCreateResponse)
            await flowService.executePostRequest(personioPayload, `https://cloud.activepieces.com/api/v1/webhooks/DfuZECAWmxvl9vohc8tXp/sync`)
        }

        console.log('YOKOY RESPONSE', response);
        return response
    }

    async updateYokoyUser(users) {
        // Reformat the values to mach the Yokoy Schema

    }

    async updatePersonioUsers(users) {

    }

}