import React from 'react';
import { createRoot } from "react-dom/client";
import {HashRouter} from 'react-router-dom';
import { Provider } from 'react-redux';
import configureStore from './Store/store';
import App from "./App";
import ErrorFallback from "./Components/common/Error/ErrorFallback";
import 'primereact/resources/themes/saga-blue/theme.css';
//import 'primereact/resources/themes/bootstrap4-dark-blue/theme.css'
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import '/node_modules/primeflex/primeflex.css'
import './App.css'; //override css, esp for NetSuite


// Add a worker for Jest tests.
/*if (process.env.NODE_ENV === 'development') {
    const { worker } = require('../reactApp/__test__mocks/browser')
    worker.start()
}*/

// Include the redux store
const store = configureStore();

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
    <Provider store={store}>
        <HashRouter>
            <ErrorFallback>
                <App />
            </ErrorFallback>
        </HashRouter>
    </Provider>
)