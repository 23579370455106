
// Generate a unique ID using a combination of the current timestamp and a random number
export const generateUniqueId = () => {
    return `${Date.now()}-${Math.random().toString(36).substring(7)}`;
};

// Function to remove an object from an array based on a key-value pair
export const removeObjectFromArrayByKey = (array, key, value) => {
    // Filter the array to exclude objects with the specified key-value pair
    const filteredArray = array.filter((obj) => obj[key] !== value);

    // Return the filtered array
    return filteredArray;
};