import {createStore, applyMiddleware, compose } from 'redux';
import rootReducer from '../Reducers/index';
import thunk from 'redux-thunk';


const showReduxDevTools = true

const store = (initialState) => {
    return createStore(
        rootReducer,
        initialState,
        compose (
            showReduxDevTools &&
            window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
        )
    );
};

export default store;