import {useSelector} from "react-redux";
import {Card} from "primereact/card";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil} from "@fortawesome/free-solid-svg-icons/faPencil";
import {faPlay} from "@fortawesome/free-solid-svg-icons/faPlay";
import {FlowExecutionService} from "../../Services/FlowExecutionService";
import {useEffect, useState} from "react";
import { ProgressSpinner } from 'primereact/progressspinner';
import localbase from "localbase";
import {useNavigate} from "react-router";

export const Dashboard = (props) => {
    const [flowRunning, setFlowRunning] = useState(false)
    const [flows, setFlows] = useState([])
    const flowService = new FlowExecutionService();
    const navigate = useNavigate();
    let db = new localbase('ipaas')

    useEffect(() => {
        let existingFlows = []
        db.collection('Flows')//.limit(1)
            .get()
            .then((flows) => {
                flows.forEach((flow) => {
                    existingFlows.push(flow.data)
                })
                setFlows(existingFlows)
            })
    }, []);

    const editFlow = (rowData) => {
        //console.log('RD', rowData)
        return <FontAwesomeIcon
            icon={faPencil} style={{color: "blue", cursor: 'pointer'}}
            onClick={() => navigate(`/flow-edit/${rowData.id}`)}
        />
    }

    const executeFlow = (rowData) => {
        return (
            <FontAwesomeIcon
                icon={faPlay} style={{color: "green", cursor: 'pointer'}}
                onClick={async () => {
                    navigate(`/datatable/${rowData.id}`)
                }}
            />
        )
    }

    return (
        <div className="grid">
            <div className="col-12">
                <Card>
                    <h2>Dashboard</h2>

                    <div className="col-12 lg:col-6 xl:col-3">
                        <div className="card mb-0">
                            <div className="flex justify-content-between mb-3">
                                <div><span className="block text-500 font-medium mb-3">Flows</span>
                                    <div className="text-900 font-medium text-xl">52</div>
                                </div>
                                <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}><i className="pi pi-shopping-cart text-blue-500 text-xl"></i></div>
                            </div>
                            <span className="text-green-500 font-medium">24 new </span><span className="text-500">since last visit</span>
                        </div>
                    </div>

                    <h3>Flows</h3>
                    <div>
                        {flowRunning ?
                            <div className="card flex justify-content-center">
                                <ProgressSpinner />
                            </div>
                        :
                            <DataTable value={flows} resizableColumns size="small" stripedRows
                                       globalFilterFields={['log_service', 'log_action', 'log_message', 'admin_name', 'created']}
                                       dataKey="id" paginator rows={10} rowsPerPageOptions={[10, 50, 100, 250]}
                                       emptyMessage="No flows found." tableStyle={{fontSize: '12px'}} //filterDisplay="row"
                                       paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                       currentPageReportTemplate={`showing {first} to {last} of {totalRecords} results`}
                            >
                                <Column header="Edit" field="" body={editFlow} />
                                <Column header="Run" field="" body={executeFlow}/>
                                <Column header="Name" field="flowName" />
                                <Column header="Description" field="description"/>
                            </DataTable>
                        }
                    </div>

                </Card>
            </div>
        </div>
    )
}