import initialState from './initialState';
import { } from "../Actions/actionTypes";

const flowReducer = (state = initialState.flows, action) => {
    switch (action.type) {



        default:
            return state
    }
}

export default flowReducer;