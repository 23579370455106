import initialState from './initialState';
import { } from "../Actions/actionTypes";

const userReducer = (state = initialState.pieces, action) => {
    switch (action.type) {



        default:
            return state
    }
}

export default userReducer;