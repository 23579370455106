import {useState} from "react";
import {Card} from "primereact/card";
import {useSelector} from "react-redux";



export const FlowStepSelector = (props) => {
    const [showChildrenPieces, setShowChildrenPieces] = useState(false)
    const [childrenPieces, setChildrenPieces] = useState([])
    const [selectedFlow, setSelectedFlow] = useState(0)
    const pieces = useSelector(state => state.pieces)
//console.log(pieces)
    const handleParentPiecesSelected = (flow) => {
        setChildrenPieces([])
        setShowChildrenPieces(false)
        setSelectedFlow(flow.id)

        if (flow.children && flow.children.length > 0) {
            setShowChildrenPieces(true)
            setChildrenPieces(flow.children)
        }
    }

    const handlePiecesSelected = (flow, child) => {
        props.addSelectedFlow(flow, child)
    }

    return (
        <div>
            <h3>Select Flow Piece</h3>
            {pieces.map((each) => {
               return (
                   <>
                       <Card style={{width: '300px', height: '50px', margin: '5px', cursor: 'pointer'}} key={each.id}
                             onClick={() => handleParentPiecesSelected(each)}
                       >
                           <span style={{fontSize: '16px', fontWeight: 'bolder'}}>
                               {each.name}
                           </span>
                       </Card>

                       {(showChildrenPieces && selectedFlow === each.id) &&
                           <>
                               {childrenPieces.map((child) => {
                                   return (
                                       <Card style={{width: '280px', height: '50px', margin: '5px', cursor: 'pointer', marginLeft: '20px'}} key={child.id}
                                             onClick={() => handlePiecesSelected(each, child)}
                                       >
                                           <span style={{fontSize: '16px', fontWeight: 'bolder'}}>
                                               {child.name}
                                           </span>
                                       </Card>
                                   )
                               })}
                           </>
                       }
                   </>
               )
            })}
        </div>
    )
}