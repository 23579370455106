// eslint-disable-next-line import/no-anonymous-default-export
export default {
    user: {
        loggedIn: true
    },
    pieces: [
        {id: 1, name: "Personio", children: [
                {id: 1, name: 'Get Personio Users', flowURL: 'https://cloud.activepieces.com/api/v1/webhooks/ALUxtIUWVDQM3CjcHFrvb/sync', httpVerb: 'GET', httpName: 'personio', userEvent: false, userEventType: null},
                {id: 2, name: 'Update Personio Users', flowURL: 'https://cloud.activepieces.com/api/v1/webhooks/DfuZECAWmxvl9vohc8tXp/sync', httpVerb: 'GET', httpName: 'yokoy', userEvent: false, userEventType: null}
            ]
        },
        {id: 2, name: "Yokoy", children: [
                {id: 1, name: 'Create Yokoy Users', flowURL: 'https://cloud.activepieces.com/api/v1/webhooks/x9PhLAiGroIApGHkLqAV2/sync', httpVerb: 'POST', httpName: 'createYokoyUser', userEvent: false, userEventType: null},
                {id: 2, name: 'Get Yokoy Users', flowURL: 'https://cloud.activepieces.com/api/v1/webhooks/uy6xFwm3RXjUJEjflNLzH/sync', httpVerb: 'GET', httpName: 'yokoy', userEvent: false, userEventType: null},
                {id: 3, name: 'Update Yokoy Users', flowURL: 'https://cloud.activepieces.com/api/v1/webhooks/kbEWx0qeGl4am5qjxLm6M/sync', httpVerb: 'POST', httpName: '', userEvent: false, userEventType: null},
            ]
        },
        {id: 3, name: "NetSuite", children: [
                {id: 1, name: 'Get NetSuite Users', flowURL: '', httpVerb: '', httpName: '', userEvent: false, userEventType: null}
            ]
        },
        {id: 4, name: "User Event", children: [
                {id: 1, name: 'View Results - Data Table', flowURL: '', httpVerb: '', httpName: '', userEvent: false, userEventType: null}
            ]
        }
    ],
    flows: [
        {
            id: 156876312, flowName: 'Create Yokoy Users From Personio.', description: 'Get all users from Personio and Yokoy.  Show a table that allows the user to update one or many Personio users to Yokoy.  Return the Yokoy number to Personio.',
                flowSteps: [
                    {"id": "1707746552116-8rktkp", "parentName": "Personio", "childName": "Get Personio Users", step: 1, flowURL: 'https://cloud.activepieces.com/api/v1/webhooks/ALUxtIUWVDQM3CjcHFrvb/sync', httpVerb: 'GET', httpName: 'personio', userEvent: false, userEventType: null},
                    {"id": "1707746555744-x7fc2", "parentName": "Yokoy", "childName": "Get Yokoy Users", step: 2, flowURL: 'https://cloud.activepieces.com/api/v1/webhooks/uy6xFwm3RXjUJEjflNLzH/sync', httpVerb: 'GET', httpName: 'yokoy', userEvent: false, userEventType: null},
                    {"id": "1707746598760-acs6na", "parentName": "User Event", "childName": "View Results - Data Table", step: 3, flowURL: '', httpVerb: '', httpName: '', userEvent: true, userEventType: 'DataTable'},
                    {"id": "1707746613549-4xyhp", "parentName": "Yokoy", "childName": "Create Yokoy Users",  step: 4, flowURL: 'https://cloud.activepieces.com/api/v1/webhooks/x9PhLAiGroIApGHkLqAV2/sync', httpVerb: 'POST', httpName: 'createYokoyUser', userEvent: false, userEventType: null},
                    {"id": "1707746614269-mnga1p", "parentName": "Yokoy", "childName": "Update Yokoy Users",  step: 5, flowURL: 'https://cloud.activepieces.com/api/v1/webhooks/kbEWx0qeGl4am5qjxLm6M', httpVerb: 'POST', httpName: '', userEvent: false, userEventType: null},
                    {"id": "1707746622882-hepfz", "parentName": "Personio", "childName": "Update Personio Users",  step: 6, flowURL: '', httpVerb: 'POST', httpName: '', userEvent: false, userEventType: null}
                ]
        }
    ]
}