import initialState from './initialState';
import {
    LOGOUT
} from "../Actions/actionTypes";

const userReducer = (state = initialState.user, action) => {
    switch (action.type) {
        case 'LOGOUT':
            return {
                ...initialState
            }


        default:
            return state
    }
}

export default userReducer;