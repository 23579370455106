import {useRef, useState} from "react";
import {Toast} from "primereact/toast";
import {SideMenuBar} from "./Components/common/SideMenuBar/SideMenuBar";
import {Route, Routes, useNavigate} from "react-router";
import {Dashboard} from "./Pages/Dashboard/Dashboard";
import {FlowNew} from "./Pages/FlowNew/FlowNew";
import {FlowExisting} from "./Pages/FlowExisting/FlowExisting";
import {UserDataTable} from "./Components/DataTable/UserDataTable";
import localbase from "localbase";
import {FlowEdit} from "./Pages/FlowEdit/FlowEdit";


function App() {
    const [userData, setUserData] = useState([])
    const myToast = useRef(null);
    const navigate = useNavigate();
    let db = new localbase('ipaas')

    const updateUserData = (data) => {
        setUserData(data)
        db.collection('FlowExecutions').add({data})
        navigate('/datatable')
    }

    const saveNewFlow = (data) => {
        console.log(data)
        db.collection('Flows').add({data}, data.id)
        navigate('/')
        myToast.current.show({severity: 'success', summary: 'SUCCESS', detail: 'The new flow has been successfully saved.', life: 5000})
    }

    const saveEditFlow = (flow) => {
        console.log(flow)
        db.collection('Flows').doc(flow.id).update({...flow})
    }

  return (
      <div className="layout-wrapper layout-static p-ripple-disabled">
          <Toast ref={myToast} />
          <SideMenuBar />
          <div className="layout-main-container">
              <div className="grid" style={{padding: '15px', paddingBottom: '0px'}}>
                  <div className="col-12">
                      <Routes>
                          <Route path="/" element={<Dashboard updateUserData={updateUserData} />} />
                          <Route path="/flow-new" element={<FlowNew saveNewFlow={saveNewFlow} />} />
                          <Route path="/flow-edit/:id?" element={<FlowEdit saveEditFlow={saveEditFlow} />} />
                          <Route path="/flow-existing" element={<FlowExisting />} />
                          {/*<Route path="/flow-execution/:id?" element={<FlowExecution />} />*/}
                          <Route path="/datatable/:id?" element={<UserDataTable userData={userData} />} />
                      </Routes>
                  </div>
              </div>
          </div>
      </div>
  );
}

export default App;
