

export const cleanYokoyPayload = (user) => {
    return {
        "firstName": user.first_name,
        "lastName": user.last_name,
        "legalEntityId": "MxZl6XbgivqslWPZJ0uE",
        "email": user.email,
        "employeeId": user.id,
        "costCenterId": "",
        "lineManagerId": "",
        "lineManagerThreshold": 999999,
        "statusActive": user.status
    }
}


export const cleanPersonioPayload = (user) => {
    return {id: user.employeeId, dynamic_10570572: user.id}
}