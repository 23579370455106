import {Card} from "primereact/card";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {useEffect, useRef, useState} from "react";
import localbase from "localbase";
import {ProgressSpinner} from "primereact/progressspinner";
import {Button} from "primereact/button";
import {FlowExecutionService} from "../../Services/FlowExecutionService";
import {Toast} from "primereact/toast";
import {useParams} from "react-router";


export const UserDataTable = () => {
    const [userData, setUserData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [selectedUsers, setSelectedUsers] = useState([])
    const [newUserFlowRunning, setNewUserFlowRunning] = useState(false)
    const [existingUserFlowRunning, setExistingUserFlowRunning] = useState(false)
    const flowService = new FlowExecutionService()
    const myToast = useRef(null);
    const params = useParams();
    let db = new localbase('ipaas')


    useEffect(() => {
        manageFlowExecution()
            //.catch((e) => console.log(e))
    }, []);

    const manageFlowExecution = async () => {
        // Get the Flow Details
        let executionFlow
        db.collection('Flows').doc(params.id).get().then(async flow => {
            console.log(flow.data)
            executionFlow = flow.data
            await flowService.executeFlow(executionFlow.flowSteps)
                .then((res) => {
                    console.log(res)
                    reformatUserData(res)
                    setIsLoading(false)
                })
                .catch((e) => {
                    console.log(e)
                    setIsLoading(false)
                })
        })


       /* db.collection('FlowExecutions').doc(params.id).get().then(flow => {
            console.log(flow.data)
            reformatUserData(flow.data)
        })*/
    }

    const reformatUserData = (userData) => {
        //console.log(userData.data.personio)
        //console.log(userData.data.yokoy)

        let personioData = userData.personio
        let yokoyData = userData.yokoy


        personioData && personioData.forEach((each) => {
            each.yokoyUser = yokoyData.filter(obj => {
                return each.id == obj.yokoy_user_id || each.email === obj.email;
            })
            switch (each.status) {
                case 'active':
                case 'leave':
                case 'onboarding':
                    each.status = true
                    break
                case 'inactive':
                    each.status = false
                    break
                default:
                    each.status = false
                    break;
            }
        })
        setUserData(personioData)
        setIsLoading(false)
    }

    const doUserImport = async () => {
        console.log(selectedUsers)
        let newYokoyUsers = []
        let existingYokoyUsers = []

        selectedUsers && selectedUsers.forEach((each) => {
            if (each.yokoy_user_id) {
                existingYokoyUsers.push(each)
            } else {
                newYokoyUsers.push(each)
            }
        })

        if (newYokoyUsers.length > 0) {
            setNewUserFlowRunning(true)
            await flowService.createYokoyUser(newYokoyUsers)
                .then((res) => {
                    //props.updateUserData(res) // Update state and show data table.
                    setNewUserFlowRunning(false)
                    let filteredArray = selectedUsers
                    userData.forEach((each) => {
                        res.filter(obj => {
                            if (obj.employeeId == each.id) {
                                each.yokoy_user_id = obj.id

                                filteredArray.filter(su => su.id !== parseInt(obj.employeeId));
                            }
                        })
                    })
                    setUserData(userData)
                    console.log('filteredArray', filteredArray)
                    console.log('userDataAfterUpdate', userData)
                    setSelectedUsers(filteredArray)

                    myToast.current.show({severity: 'success', summary: 'SUCCESS', detail: 'The Yokoy Users where successfully created.', life: 5000})
                })
                .catch((e) => {
                    console.log(e)
                    setNewUserFlowRunning(false)
                    myToast.current.show({severity: 'error', summary: 'ERROR', detail: 'An unexpected error took place while creating new Yokoy Users.', life: 5000})
                })
        }

        if (existingYokoyUsers.length > 0) {
            setExistingUserFlowRunning(true)
            await flowService.updateYokoyUser(existingYokoyUsers)
                .then((res) => {
                    //props.updateUserData(res) // Update state and show data table.
                    setExistingUserFlowRunning(false)
                })
                .catch((e) => {
                    console.log(e)
                    setExistingUserFlowRunning(false)
                    myToast.current.show({severity: 'error', summary: 'ERROR', detail: 'An unexpected error took place while updating existing Yokoy Users.', life: 5000})
                })
        }
    }


    //console.log('USERDATA', userData)
    return (
        <div className="grid">
            <Toast ref={myToast} />
            <div className="col-12">
                <Card>
                    <h2>Personio Yokoy User Update</h2>

                    {(isLoading || existingUserFlowRunning || newUserFlowRunning) ?
                        <div className="card flex justify-content-center">
                            <ProgressSpinner/>
                        </div>
                    :
                        <div>
                            <div className="grid">
                                <div className="col-12" style={{width: '100%'}}>
                                    <div style={{float: 'right', width: '280px'}}>
                                        <Button type="button" label="Import/Update Selected Users"
                                                icon="pi pi-save"
                                               // disabled={(importButtonDisabled || (selectedUsers.length === 0 && (tableView !== 'filtered' || tableView !== 'activeStatusDiff')))}
                                                onClick={() => doUserImport()}
                                        />
                                    </div>
                                </div>
                            </div>

                            <DataTable value={userData} resizableColumns size="small" stripedRows
                                   globalFilterFields={['log_service', 'log_action', 'log_message', 'admin_name', 'created']}
                                   dataKey="id" paginator rows={10} rowsPerPageOptions={[10, 50, 100, 250]}
                                   emptyMessage="No users found." tableStyle={{fontSize: '12px'}} //filterDisplay="row"
                                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                   currentPageReportTemplate={`showing {first} to {last} of {totalRecords} results`}
                                   selection={selectedUsers} onSelectionChange={e => setSelectedUsers(e.value)}
                                   selectionMode="checkbox" //isDataSelectable={isRowSelectable}
                        >
                             <Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
                             <Column header="ID" field="id"/>
                             <Column header="First Name" field="first_name"/>
                             <Column header="Last Name" field="last_name"/>
                             <Column header="Subsidiary" field="subcompany"/>
                             <Column header="Yokoy User ID" field="yokoy_user_id"/>
                        </DataTable>
                        </div>
                    }
                </Card>
            </div>
        </div>
        )
    }