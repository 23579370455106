import React, {useState} from 'react';
import {Card} from "primereact/card";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import {FlowStepSelector} from "./SideMenus/FlowStepSelector";
import {generateUniqueId, removeObjectFromArrayByKey} from "../../Utils/GeneralUtils";
import {faDownLong} from "@fortawesome/free-solid-svg-icons/faDownLong";
import {Chip} from "primereact/chip";
import {InputTextarea} from "primereact/inputtextarea";
import {Button} from "primereact/button";
import {faSave} from "@fortawesome/free-solid-svg-icons/faSave";
import {InputText} from "primereact/inputtext";

export const FlowNew = (props) => {
    const [descriptionValue, setDescriptionValue] = useState('');
    const [flowNameValue, setFlowNameValue] = useState('');
    const [newFlow, setNewFlow] = useState([]);

    const addSelectedFlow = (parent, child) => {
console.log('P', parent)
console.log('C', child)
        let flowStep = {
            id: generateUniqueId(),
            parentName: parent.name,
            childName: child.name,
            ...child
        }
        const newArr = [...newFlow]
        newArr.push(flowStep)
        console.log(newArr)
        setNewFlow(newArr)
    }

    const deleteFlowStep = (id) => {
        let updatedFlow = removeObjectFromArrayByKey(newFlow, 'id', id)

        setNewFlow(updatedFlow)
    }

    const saveFlow = () => {
        let flowDetails = {
            id: generateUniqueId(),
            flowName: flowNameValue,
            description: descriptionValue,
            flowSteps: [
                ...newFlow
            ]
        }

        props.saveNewFlow(flowDetails)
    }

    const updateFlowName = (value) => {
        setFlowNameValue(value)
    }

    const updateFlowDescription = (value) => {
        setDescriptionValue(value)
    }

    return (
        <>
            <div style={{width: '100%'}}>
                <div className="flow-builder" style={{
                    overflow: 'scroll', display: 'inline-block', padding: '10px',
                    overflowY: 'scroll', height: '90vh'
                }}>
                    <h3>Add New Flow</h3>
                    <div style={{width: '250px', paddingLeft: '1rem', textAlign: 'center'}}>
                        <Chip label="Start"/>
                    </div>
                    {newFlow.map((each) => {
                        return (
                            <div key={each.id}>
                                <Card style={{width: '250px', height: '75px', margin: '10px'}} onClick={() => {
                                }}>
                                    <div style={{
                                        fontSize: '14px',
                                        whiteSpace: 'nowrap'
                                    }}>{each.parentName} - {each.childName}</div>
                                    <div style={{float: 'right', paddingTop: '7px'}}>
                                        {/*<FontAwesomeIcon icon={faPlusSquare} style={{
                                            cursor: 'pointer',
                                            color: "blue",
                                            marginRight: '10px'
                                        }}/>*/}
                                        <FontAwesomeIcon icon={faTrash} style={{cursor: 'pointer', color: "red"}}
                                                         onClick={() => deleteFlowStep(each.id)}
                                        />
                                    </div>
                                </Card>

                                <div style={{width: '250px', paddingLeft: '1rem', textAlign: 'center'}}>
                                    <FontAwesomeIcon icon={faDownLong}/>
                                </div>
                            </div>
                        )
                    })}
                    <div style={{width: '250px', paddingLeft: '1rem', textAlign: 'center'}}>
                        <Chip label="End"/>
                    </div>
                </div>

                <div style={{width: '300px', float: 'right'}}>
                    <h3>Flow Settings</h3>

                    <h5>Flow Details</h5>
                    <InputText value={flowNameValue}
                               onChange={(e) => {
                                   updateFlowName(e.target.value)
                               }}
                   />
                    <InputTextarea value={descriptionValue}
                                   onChange={(e) => {
                                       updateFlowDescription(e.target.value)
                                       //setValue(e.target.value)
                                   }}
                                   rows={5} cols={30}
                    />

                    <Button label="Save" type="button" icon={<FontAwesomeIcon icon={faSave} style={{marginRight: '15px'}} />} onClick={() => saveFlow()} />

                    <FlowStepSelector
                        addSelectedFlow={addSelectedFlow}
                    />
                </div>
            </div>
        </>
    )
}