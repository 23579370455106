import {useSelector} from "react-redux";
import {Card} from "primereact/card";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil} from "@fortawesome/free-solid-svg-icons/faPencil";
import {faPlay} from "@fortawesome/free-solid-svg-icons/faPlay";
import {FlowExecutionService} from "../../Services/FlowExecutionService";
import {useEffect, useState} from "react";
import { ProgressSpinner } from 'primereact/progressspinner';
import localbase from "localbase";
import {useNavigate} from "react-router";

export const FlowExisting = (props) => {
    const [flowRunning, setFlowRunning] = useState(false)
    const [savedFlows, setSavedFlows] = useState([])
    const flows = useSelector(state => state.flows)
    const flowService = new FlowExecutionService()
    const navigate = useNavigate()
    let db = new localbase('ipaas')


    useEffect(() => {
        let flowData = []
        db.collection('Flows')
            .get()
            .then(flows => {
                flows.forEach((flow) => {
                    flowData.push(flow.data)
                })
                setSavedFlows(flowData)
            })
    }, []);

    const editFlow = (rowData) => {
        return (
            <FontAwesomeIcon
                icon={faPencil} style={{color: "blue", cursor: 'pointer'}}
                onClick={() => navigate(`/flow-edit/${rowData.id}`)}
            />
        )
    }

    const executeFlow = (rowData) => {
        return (
            <FontAwesomeIcon
                 icon={faPlay} style={{color: "green", cursor: 'pointer'}}
                 onClick={async () => {
                     navigate(`/datatable/${rowData.id}`)
                 }}
            />
        )
    }

    return (
        <div className="grid">
            <div className="col-12">
                <Card>
                    <h2>Existing Flows</h2>
                    <div>
                        {flowRunning ?
                            <div className="card flex justify-content-center">
                                <ProgressSpinner />
                            </div>
                        :
                            <DataTable value={savedFlows} resizableColumns size="small" stripedRows
                                       globalFilterFields={['log_service', 'log_action', 'log_message', 'admin_name', 'created']}
                                       dataKey="id" paginator rows={10} rowsPerPageOptions={[10, 50, 100, 250]}
                                       emptyMessage="No flows found." tableStyle={{fontSize: '12px'}} //filterDisplay="row"
                                       paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                       currentPageReportTemplate={`showing {first} to {last} of {totalRecords} results`}
                            >
                                <Column header="Edit" field="" body={editFlow} />
                                <Column header="Run" field="" body={executeFlow}/>
                                <Column header="Name" field="flowName" />
                                <Column header="Description" field="description"/>
                            </DataTable>
                        }
                    </div>

                </Card>
            </div>
        </div>
    )
}