import {useNavigate} from "react-router";


export const SideMenuBar = () => {
    const navigate = useNavigate();

    return (
        <div className="layout-sidebar">
            <h3>MENU</h3>

            <div style={{paddingTop: '10px', cursor: "pointer"}} onClick={() => navigate('/')}>
                Dashboard
            </div>
            <div style={{paddingTop: '10px', cursor: "pointer"}} onClick={() => navigate('/flow-new')}>
                Add New Flow
            </div>
            <div style={{paddingTop: '10px', cursor: "pointer"}} onClick={() => navigate('/flow-existing')}>
                Existing Flows
            </div>
        </div>
    )
}